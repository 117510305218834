<template>
  <div>
    <div
      v-if="$route.fullPath === '/member_center/bank-card'"
      class="bank-cards-view"
    >
      <template v-if="!loading">
        <div v-if="$store.state.bankCards.length === 0" class="no-card">
          <p>目前暂无绑定银行卡</p>
        </div>
        <div v-if="$store.state.bankCards.length > 0" class="bank-cards">
          <router-link
            v-for="(card, idx) in $store.state.bankCards"
            :key="idx"
            :to="`/member_center/bank-card/detail/${card.card_id}`"
            class="bank-card"
          >
            <div :class="['card-inner', card.pic_name]">
              <div class="bank-info">
                <div :class="['bank-logo', card.pic_name]"></div>
              </div>
              <p class="card-number" v-text="card.card_code"></p>
            </div>
          </router-link>
        </div>
        <router-link
          class="add-card btn active"
          to="/member_center/bank-card/add"
        >
          &plus;添加银行卡
        </router-link>
      </template>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
export default {
  name: 'bank',
  components: {},
  data() {
    return {
      loading: true
    }
  },
  async created() {
    await this.getBankCards()
  },
  methods: {
    async getBankCards() {
      await this.$store.dispatch('getBankCards')
      console.log('bankCards: ', this.$store.state.bankCards)
      this.loading = false
    }
  }
}

// for (let i = 0; i < objPic.length; i++) {
// 	const item = objPic[i];
// 	$(".choose .wrap ul").append(`<li><img src="${item.src}" flag="${item.pic_name}">${item.name}</li>`)
// }
// $(".choose .wrap ul li").click(function(e) {
// 	e.stopPropagation();
// 	var flag = $(e.currentTarget).find('img').attr('flag');
// 	var pic = $(e.currentTarget).find('img').clone();
// 	var name = $(e.currentTarget).text();
// 	$('#picName').val(flag);
// 	$('#cardName').val(name);
// 	$('.bankName').text(name);
// 	$('.bankName').prepend(pic);
// 	$('.chooseBtn').css('color', '#101010');
// 	$('.choose').hide();
// });

// $('.chooseBtn').parent().click(function (e) {
//     e.preventDefault();
//     $('.choose').toggle();
// });
// $(function(){
// 	//加载手机号前缀
// 	loadPhonePrefix();
// });
// /**
// * 定時器
// */
// var interValObj; //timer变量，控制时间
// var count = 120; //间隔函数，1秒执行
// var curCount;//当前剩余秒数
// //定时器
// function cardChatTimer(){
// 	$("#sendCode").prop("onclick",null).off("click");
// 	curCount = count;
// 	$("#sendCode").html(curCount);
// 	interValObj = window.setInterval(setCardChatTimer, 500); //启动计时器，1秒执行一次
// }
// //timer处理函数
// function setCardChatTimer(){
//   if(curCount == 1){
//       window.clearInterval(interValObj);//停止计时器
//       $("#sendCode").attr("onclick","sendPhoneSms();");
//       $("#sendCode").html("獲取驗證碼");
//   }else{
// 	  curCount--;
//       $("#sendCode").html(curCount);
//   }
// }
// //賬號註冊-請求驗證碼
// function sendPhoneSms(){
// 	layui.use(['layer'], function () {
// 		var phoneCode = $("#phoneCode").val();
// 		var area_prefix = $("#area_prefix").val();
// 		if(getStr(phoneCode) == ""){
// 			layer.msg("請輸入手機號碼");
// 			return ;
// 		}
// 		var jsonPar = {
// 			"area_prefix":area_prefix,
// 	        "user_phone":phoneCode,
// 	        "phone_flag":"addbank_"
// 		};
// 		var jsonData = {
// 		      'mapStr': jsonToStr(jsonPar)
// 		};
// 		layer.ready(function() {layer.load(0,{time: 500});});
// 		$.post('sendPhoneCodeSms.do', jsonData, function (data) {
// 			  layer.closeAll('loading');
// 		      if (data.code == "success") {
// 		    	  cardChatTimer();
// 		      }else{
// 		    	layer.msg(data.info);
// 		      	return ;
// 		      }
// 		 }, 'json');
// 	});
// }
// /**
//  * 檢查卡號來顯示卡名稱
//  * @param obj
//  * @returns
//  */
// function checkBankCode(obj){
// 	layui.use(['layer'], function () {
// 	var card_code = $(obj).val();
// 	var jsonPar = {
//         	"card_code":card_code
//     };
//     var jsonData = {
//     	'mapStr' : jsonToStr(jsonPar)
//     };
//     if(getStr(card_code) == ""){
//     	return false;
//     }
//     layer.ready(function() {layer.load(0,{time: 500});});
// 	$.post('member_card_checkBankCard.do', jsonData, function(data) {
// 		layer.closeAll('loading');
// 		if (data.code == "success") {
// 			//$("#card_name").val(data.info);
// 			return false;
// 		}else{
// 			layer.msg('卡號【'+card_code+'】不符合規則！');
// 			$(obj).val('');
// 			return false;
// 		}
// 	}, 'json');
// 	});
// }
// //保存
// function saveCardInfo(){
// 	layui.use(['layer'], function () {
// 	var realName = $("#realName").val();
// 	var picName = $("#picName").val();
// 	var cardName = $("#cardName").val();
// 	var cardCode = $("#cardCode").val();
// 	var phoneCode = $("#phoneCode").val();
// 	var area_prefix =$("#area_prefix").val();
// 	var vCode = $("#vCode").val();
// 	if(getStr(realName) == ""){
// 		layer.msg("請輸入持卡人");
// 		return false;
// 	}
// 	if(getStr(phoneCode) == ""){
// 		layer.msg("請輸入手機號碼");
// 		return false;
// 	}
// 	if(getStr(picName) == ""){
// 		layer.msg('請選擇銀行卡');
// 		return false;
// 	}
// 	if(getStr(cardCode) == ""){
// 		layer.msg("請輸入銀行卡號");
// 		return false;
// 	}
// 	if(getStr(vCode) == ""){
// 		layer.msg("請輸入驗證碼");
// 		return false;
// 	}
// 	var jsonPar = {
// 		"realName":realName,
// 		"picName":picName,
// 		"cardName":cardName,
// 		"cardCode":cardCode,
// 		"phoneCode":phoneCode,
// 		"area_prefix":area_prefix,
//         "vCode":vCode,
//         "phone_flag":"addbank_"
// 	};
// 	var jsonData = {
// 	      'mapStr': jsonToStr(jsonPar)
// 	};
// 	layer.ready(function() {layer.load(0,{time: 500});});
// 	$.post('member_card_addBankCard.do', jsonData, function (data) {
// 		var mapStr = {};
// 		if (data.code == "success") {
// 			var info = strToJson(data.data);
// 			if(info.flag == "0"){
// 				layer.msg('添加成功！');
// 				fromSubmit("binding_bank_page.do",jsonToStr(mapStr));
// 			}else{
// 				layer.closeAll('loading');
// 				layer.msg(info.msg);
// 			}
// 		}else{
// 			signOut();
// 		}
// 	 }, 'json');
// 	});
// }
// function comeback(){
// 	layui.use(['layer'], function () {
// 		layer.ready(function() {layer.load(0,{time: 500});});
// 		var mapStr = {};
// 	    fromSubmit("binding_bank_page.do",jsonToStr(mapStr));
// 	});
// }
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';
@import '~@/assets/style/bank-card.scss';

.bank-cards-view {
  color: #ccc;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-card {
  margin-top: 5rem;

  > p {
    font-size: 0.9rem;

    @include md {
      font-size: 0.8rem;
    }
  }
}

.bank-cards {
  margin: 0 auto;

  .bank-card {
    color: #e0e0e0;

    .card-inner {
      @include card-bg;

      .bank-info {
        .bank-logo {
          width: 3rem;
          height: 3rem;

          @include bank-logo;
        }
      }

      .card-number {
        letter-spacing: 1px;
        font-size: 0.8rem;
        font-weight: 100;
      }
    }
  }
}

.add-card {
  margin-top: 1rem;
  color: white;
}
</style>
